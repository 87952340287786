import { Location } from '@angular/common';
import { LivestockService } from '../../shared/service/livestock.service';
import { AudioService } from '../../shared/component/audio/audio.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { VideoService } from '../../shared/component/video/video.service';
import { UserService } from '../../shared/service/user.service';
import { Parametre } from '../../shared/service/parametre';
import { ImageService } from '../../shared/component/image-modal/image.service';
import { LayoutService } from '../../shared/service/layout.service';
import { MatSnackBar } from '@angular/material/snack-bar';
const PARAMETRE = Parametre.getInstance();
import { environment } from '../../../environments/environment';
import Utils from "../../shared/utils/utils";
import { TranslationService } from '../../shared/service/translation.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GeoLocationService } from '../../shared/service/location';
import { NgForm, FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { InfoDialogComponent } from '../../shared/component/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { LinkService } from '../../shared/component/link/link.service';
@Component({
  selector: 'app-family-practices-subjects',
  templateUrl: './family-practices-subjects.component.html',
  styleUrls: ['./family-practices-subjects.component.scss']
})

export class FamilyPracticesSubjectsComponent implements OnInit, OnDestroy {
  public advices: any;
  public objectKeys = Object.keys;
  speciesName: any;
  public arrSpeciesDetails: any = [];
  public loader: boolean = false;
  public arrTabInfo: any;
  public isCount: any;
  public arrSpeciesInfo: any = [];
  public txtSpeech: any;
  public generalData: any;
  lang_code: any;
  groups: any = [];
  providers: any;
  topics: any = [];
  appTheme: any;
  keyMessage = '';
  selectedIndex = 0;
  dataSource = [];
  animalType = [];
  animalWeight = [];
  formSubmit: boolean = false;
  calculatorForm: FormGroup;
  nutritionalData: any;
  animalCount = Array.from({ length: 100 }, (_, i) => i + 1);
  calculator_Guide: any;
  subscriptions: Subscription[] = [];
  nutritionalArray: string[] = [];
  unitLabelsArray: string[] = [];
  nutritionLabels: string[] = ['adf', 'cp', 'dm', 'me', 'ndf'];
  selectedTopicId: string;
  constructor(
    private audioService: AudioService,
    private location: Location,
    private route: ActivatedRoute,
    private livestockService: LivestockService,
    private videoService: VideoService,
    private imageService: ImageService,
    public gaService: GoogleAnalyticsService,
    public geoLocationService: GeoLocationService,
    public layoutService: LayoutService,
    private fb: FormBuilder,
    public toast: MatSnackBar,
    private TranslateS: TranslationService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private linkService: LinkService
  ) {
    this.spinner.show();
    layoutService.refresh();
    this.calculatorForm = this.fb.group({
      animal: ['', [Validators.required]],
      weightCategory: ['', [Validators.required]],
      animalCount: [1, [Validators.required]],
      nutritions: this.fb.array([]),
    });
  }

  public back() {
    this.location.back();
  }

  ngOnInit() {
    const themes = JSON.parse(localStorage.getItem('appThemes'));
    this.lang_code = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).code;
    UserService.langCodeTextSpeech = JSON.parse(localStorage.getItem(environment.userLanguageStorageKey)).text_to_speech_code;
    this.txtSpeech = UserService.langCodeTextSpeech;
    this.route.params.subscribe(response => {
      this.appTheme = themes.filter(_ => _.id === response.themeId)[0];
      this.selectedTopicId = response.topicId;
      this.keyMessage = this.route.snapshot.queryParams['keyMessage'];

      this.subscriptions.push(this.livestockService.getCalculatorGuide(response.themeId, this.lang_code).subscribe(res => {
        this.calculator_Guide = res;
      }));

      this.subscriptions.push(this.livestockService.getMessageGroup().subscribe(res => {
        this.groups = res;
      }));

      this.subscriptions.push(this.livestockService.getProviders().subscribe(res => {
        this.providers = res;
      }));
      this.subscriptions.push(this.livestockService.getFeedDatabase(response.id).subscribe(res => {
        if (res) {
          res = Utils.sort(res, 'classification').reverse();
          this.nutritions.clear();
          res.forEach(_ => {
            this.nutritions.push(this.newNutritions());
          });
          this.dataSource = res;
        }
      }));
      this.subscriptions.push(this.livestockService.getFeedAnimals(response.id).subscribe(res => {
        this.animalType = res;
      }));
      this.subscriptions.push(this.livestockService.getDimensionDocument(response.id).subscribe(res => {
        this.spinner.hide();
        this.arrSpeciesDetails = res;
        //this.speciesName = this.arrSpeciesDetails.species;
        this.subscriptions.push(this.livestockService.getMessageForSpecie('dimension_id', this.arrSpeciesDetails.id).subscribe(messgae => {
          messgae = messgae.filter(_ => _.status == undefined || _.status);
          this.arrTabInfo = messgae ? messgae.reduce(this.messagesTabs, {}) : {};
          var topics = [];
          var filteredGroups = this.groups.filter(_ => _.group_type == 'topic' && Object.keys(this.arrTabInfo).includes(_.id));
          filteredGroups.forEach(element => {
            topics.push(element.id);
          });
          this.topics = topics;
          if (this.selectedTopicId) {
            this.topics = [this.topics.find(v => v == this.selectedTopicId)]
          }
          this.topics.forEach(element => {
            var obj = {};
            var filteredGroups = this.groups.filter(_ => _.group_type == "group" && Object.keys(this.arrTabInfo[element]).includes(_.id));
            filteredGroups.forEach(ele => {
              obj[ele.id] = this.arrTabInfo[element][ele.id];
            });
            this.arrTabInfo[element] = obj;
          });
          this.isCount = Object.keys(this.arrTabInfo).length;
          this.loader = true;
          // this.selectedIndex = 2;
        }));
        this.arrSpeciesInfo = res;
        this.loader = true;
      }));
    });
  }
  get nutritions(): FormArray {
    return this.calculatorForm.get("nutritions") as FormArray
  }
  newNutritions(): FormGroup {
    return this.fb.group({
      intake: [{ value: '', disabled: true }],
      price: [{ value: '', disabled: true }],
      checked: false
    })
  }
  messagesTabs(acc, msg) {
    if (msg.topic_id !== "") {
      if (!acc[msg.topic_id]) {
        acc[msg.topic_id] = {};
      }
      if (!acc[msg.topic_id][msg.group_id]) {
        acc[msg.topic_id][msg.group_id] = [msg];
        return acc;
      }
      acc[msg.topic_id][msg.group_id].push(msg);
    }
    return acc;
  }
  setMyStyles() {
    return Utils.reverseContent();
  }


  tabChange(i) {
    this.gaService.event('Family_Practices_' + this.speciesName, 'Tab_Change', i.tab.textLabel);
  }
  getGroupDetails(id) {
    if (id == '') return 'system.information_tab';
    if (this.groups && this.groups.length) {
      var data = this.groups.filter(_ => _.id == id);
      return data.length ? data[0].name : id;
    } else return id
  }

  getProviderDetaiils(id, returnValue) {
    if (this.providers && this.providers.length) {
      var data = this.providers.filter(_ => _.id == id);
      return data.length ? data[0][returnValue] : id;
    } else return id
  }
  isTabOpened(keyMessages) {
    let value = this.keyMessage && keyMessages.filter(_ => _.id == this.keyMessage).length ? true : false;
    if (value) {
      this.topics.forEach((element, index) => {
        if (keyMessages.filter(_ => _.id == this.keyMessage)[0].topic_id == element) {
          this.selectedIndex = this.arrSpeciesDetails.info && this.arrSpeciesDetails.info[this.lang_code] ? index + 1 : index;
        }
      });
    }
    return value;
  }
  getAnimalWeight(event) {
    this.spinner.show();
    this.calculatorForm.controls['weightCategory'].setValue('');
    this.animalWeight = [];
    this.subscriptions.push(this.livestockService.getFeedAnimalsWeight(this.arrSpeciesDetails.id, event.value).subscribe(res => {
      this.animalWeight = Utils.sort(res, 'min_weight');
      this.spinner.hide();
    }));
  }
  calculate() {
    if (!this.calculatorForm.valid) {
      return;
    }
    let url = environment.feedCalculator;
    url = url.replace('{dimention}', this.arrSpeciesDetails.id);
    url = url.replace('{animal}', this.calculatorForm.value.animal);
    let feed_intake: any = [];
    this.dataSource.forEach((_: any, index) => {
      if (this.nutritions.value[index].checked) {
        feed_intake.push({
          "id": _.id,
          "name": _.name,
          "kg_intake": this.nutritions.value[index].intake,
          "price": this.nutritions.value[index].price
        })
      }
    });
    if (!feed_intake.length) {
      this.toast.open(this.TranslateS.translate("animal_feed.error_message"), this.TranslateS.translate("Post.Ok"));
      return;
    }
    var data = {
      "nutr_req_id": this.calculatorForm.value.weightCategory,
      "number_animals": this.calculatorForm.value.animalCount,
      "feed_intake": feed_intake
    }
    this.spinner.show();
    this.formSubmit = true;
    var unitMeasures = {};
    this.subscriptions.push(this.livestockService.calculateFeed(url, data).subscribe(res => {
      if (res) {
        this.nutritionalData = res;
        unitMeasures = this.nutritionalData.unit_measures;
        this.objectKeys(this.nutritionalData.herd_report.intake).forEach(label => {
          this.addUnitMeasures(label, unitMeasures);
          this.addUnitData(label);
        });
      }
      this.formSubmit = false;
      this.spinner.hide();
    }));
  }

  private addUnitMeasures(label: string, unitMeasures: {}) {
    const concatenatedLabel = label.toUpperCase() + ` (${unitMeasures[label]})`;
    if (!this.nutritionalArray.includes(concatenatedLabel.trim())) {
      this.nutritionalArray.push(concatenatedLabel.trim());
    }
    const desiredOrder = ['DM (kg/d)', 'CP (kg/d)', 'ME (Mj/d)', 'NDF (kg/d)', 'ADF (kg/d)'];
    this.nutritionalArray.sort((a, b) => {
      return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
    });
  }

  private addUnitData(label: string) {
    if (!this.unitLabelsArray.includes(label))
      this.unitLabelsArray.push(label);
    const desiredOrder = ['dm', 'cp', 'me', 'ndf', 'adf'];
    this.unitLabelsArray.sort((a, b) => {
      return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
    });
  }

  removeSetValidators(index) {
    if (this.nutritions.value[index].checked) {
      this.nutritions.controls[index]['controls']['intake'].setValidators([Validators.required]);
      this.nutritions.controls[index]['controls']['intake'].enable();
      this.nutritions.controls[index]['controls']['price'].setValidators([Validators.required]);
      this.nutritions.controls[index]['controls']['price'].enable();
    } else {
      this.nutritions.controls[index]['controls']['intake'].clearValidators();
      this.nutritions.controls[index]['controls']['intake'].setValue('');
      this.nutritions.controls[index]['controls']['intake'].disable();
      this.nutritions.controls[index]['controls']['price'].clearValidators();
      this.nutritions.controls[index]['controls']['price'].setValue('');
      this.nutritions.controls[index]['controls']['price'].disable();
    }
    this.nutritions.controls[index]['controls']['intake'].updateValueAndValidity();
    this.nutritions.controls[index]['controls']['price'].updateValueAndValidity();
  }
  getNutritionName(id) {
    return this.dataSource.filter(_ => _.id == id)[0].name;
  }
  getDiffrece(l) {
    let per = ((this.nutritionalData.herd_report.intake[l] / this.nutritionalData.herd_report.requirements[l]) * 100);
    return Math.abs(per - 100);
  }
  reset() {
    this.calculatorForm.controls['weightCategory'].setValue('');
    this.calculatorForm.controls['animalCount'].setValue(1);
    this.calculatorForm.controls['animal'].setValue('');
    this.nutritionalData = null;
    this.dataSource.forEach((_: any, index) => {
      if (this.nutritions.value[index].checked) {
        this.nutritions.controls[index]['controls']['checked'].setValue(false);
        this.removeSetValidators(index);
      }
    });
  }
  openHelpDialog(): void {
    this.dialog.open(InfoDialogComponent, {
      data: this.calculator_Guide
    });
  }
  openMediaPlayer(src) {
    this.audioService.openMediaPlayer(src);
  }

  openMediaPlayerForVideo(src, isYoutube?) {
    this.videoService.openMediaPlayer(src, isYoutube);
  }

  openMediaForImage(src) {
    this.imageService.openMediaPlayer(src);
  }

  openMediaForSpeech(content) {
    const strUrl = PARAMETRE.strTextToSpeechUrl + encodeURIComponent(content) + "&language=" + this.txtSpeech;
    this.audioService.openMediaPlayer(strUrl);
  }
  public export(): void {
    this.formSubmit = true;
    this.spinner.show();
    let feed_intake: any = [[], [], []];
    let animal = this.animalType.find(_ => _.id == this.calculatorForm.value.animal).name;
    animal = animal[this.lang_code] ? animal[this.lang_code] : animal['en'];
    this.dataSource.forEach((_: any, index) => {
      if (this.nutritions.value[index].checked) {
        feed_intake[0].push(_.name[this.lang_code]);
        feed_intake[1].push(this.nutritions.value[index].intake);
        feed_intake[2].push(this.nutritions.value[index].price);
      }
    });
    feed_intake[0].push({ text: this.TranslateS.translate("animal_feed.total_price"), bold: true });
    feed_intake[1].push('');
    feed_intake[2].push({ text: this.nutritionalData.herd_report.prices.total, bold: true });
    const docDefinition = {
      content: [
        { text: this.TranslateS.translate("animal_feed.report_header"), style: "header" },
        {
          text: [
            { text: "Date : ", style: 'content' },
            { text: this.datePipe.transform(new Date(), 'dd/MM/yyyy'), style: 'content', bold: true }
          ],
          style: 'content'
        },
        {
          columns: [
            {
              width: '50%',
              text: [
                { text: this.TranslateS.translate("animal_feed.animal_type") + ' : ', style: 'content' },
                { text: animal, style: 'content', bold: true },
              ]
            },
            {
              width: '50%',
              text: [
                { text: this.TranslateS.translate("animal_feed.animal_count") + ' : ', style: 'content' },
                { text: this.calculatorForm.value.animalCount, style: 'content', bold: true },
              ]
            }
          ],
          columnGap: 10,
          style: 'content'
        },
        {
          style: "table",
          table: {
            headerRows: 1,
            body: [
              [{
                text: this.TranslateS.translate("animal_feed.name_header"), bold: true
              }, { text: this.TranslateS.translate("animal_feed.intake_header"), bold: true }, { text: this.TranslateS.translate("animal_feed.price_header"), bold: true }],
              feed_intake
            ]
          }
        }
      ],
      styles: {
        header: {
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 20],
          alignment: 'center'
        },
        table: {
          margin: [0, 5, 0, 15]
        },
        content: {
          fontSize: 12,
          margin: [0, 0, 0, 15],
        },
      }
    };
    pdfMake.createPdf(docDefinition).download(this.arrSpeciesDetails.name[this.lang_code] + '.pdf');
    this.formSubmit = false;
    this.spinner.hide();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
